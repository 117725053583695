/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HeroText } from '../components/HeroText';
import { HeroHalfImage } from '../components/HeroHalfImage';
import { htmlSerializer } from '../utilities/htmlSerializer';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { HeroFullImage } from '../components/HeroFullImage';

const parentTags = ['expertise'];

const isFullWidthImage = ({ heroStyle, image }) => {
  const {
    thumbnails: {
      fw_main_hero: fwMainHero,
      fw_mobile: fwMobile,
    } = {},
  } = image;

  return (
    (heroStyle === 'Full width Image')
    && fwMainHero
    && fwMobile
  );
};

const getFwImageProps = image => {
  const {
    thumbnails: {
      fw_main_hero: { gatsbyImageData },
      fw_mobile: fwMobile,
    } = {},
  } = image;

  return {
    ...image,
    gatsbyImageData,
    thumbnails: {
      mobile: fwMobile,
    },
  };
};

const getHeroImageComponent = ({ heroStyle, image }) => {
  if (isFullWidthImage({ heroStyle, image })) {
    return HeroFullImage;
  }

  if (heroStyle === 'Text with Image') {
    return HeroHalfImage;
  }

  return HeroText;
};

const getHeroImageProps = ({
  heroStyle,
  image,
  spaceBelow,
  title,
  plainText,
  richText,
  dottedBackground,
}) => {
  if (isFullWidthImage({ heroStyle, image })) {
    return {
      title,
      text: plainText,
      image: getFwImageProps(image),
      textColor: 'Light',
      spaceBelow,
    };
  }

  return {
    title,
    image,
    spaceBelow,
    text: <RichText render={richText} {...{ htmlSerializer }} />,
    size: (heroStyle === 'Large Text') ? 'large' : 'regular',
    decoration: mapDecorationValues(dottedBackground),
  };
};

const LandingPageTemplate = ({ data }) => {
  if (!data) return null;
  const {
    surtitle: { text: title },
    title: { richText, text: plainText },
    hero_style: heroStyle,
    main_hero_image: image,
    space_below: spaceBelow,
    dotted_background: dottedBackground,
    body,
  } = data.prismicLandingPage.data;
  const { tags } = data.prismicLandingPage;

  // check if this page has a parent
  const hasParent = !!parentTags.find(parentTag => tags.some(tag => tag === parentTag));
  const seoTitle = hasParent
    ? title
    : `${RichText.asText(richText)} | ${title}`;

  const HeroComponent = getHeroImageComponent({ heroStyle, image });

  return (
    <Layout>
      <SEO title={seoTitle} />
      <HeroComponent {...getHeroImageProps({
        heroStyle,
        image,
        title,
        plainText,
        spaceBelow,
        richText,
        dottedBackground,
      })}
      />
      <SliceZone sliceZone={body} />
    </Layout>
  );
};

// useful reference -
export const query = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingPage(id: { eq: $id }) {
      _previewable
      data {
        surtitle {
          text
        }
        title {
          richText
          text
        }
        hero_style
        main_hero_image {
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          thumbnails {
            mobile {
              gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
            }
            fw_main_hero {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
            fw_mobile {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
        space_below
        dotted_background
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...LandingPageDataBodyQuote
          ...LandingPageDataBodyFiftyFiftyPanels
          ...LandingPageDataBodyCaseStudyList
          ...LandingPageDataBodyServicesBlock
          ...LandingPageDataBodyTeamListBlock
          ...LandingPageDataBodyExpandablesListBlock
          ...LandingPageDataBodyFeatureTextBlock
          ...LandingPageDataBodyInlineImagesBlock
          ...LandingPageDataBodyRelatedPosts
          ...LandingPageDataBodyRichText
          ...LandingPageDataBodyLogos
          ...LandingPageDataBodyCaseStudyTaggedList
        }
      }
      tags
    }
  }
`;

LandingPageTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(LandingPageTemplate);
