import { graphql, useStaticQuery } from 'gatsby';

export const useCaseStudyTaggedQuery = () => (
  useStaticQuery(graphql`
    {
      allPrismicCaseStudy(
        filter: {tags: {nin: []}}
        sort: {fields: last_publication_date, order: DESC}
      ) {
        edges {
          node {
            data {
              display_title {
                text
              }
              intro_title {
                text
              }
              main_hero_image {
                thumbnails {
                  card50 {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                  }
                  card100 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
            tags
          }
        }
      }
    }
  `)
);
