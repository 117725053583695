/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { fluidScale } from '../composableStyles/fluidScale';
import { widestScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';

const legalText = theme => css`
  color: ${theme.colors.grey};
  letter-spacing: ${0.2 / 11}em;
  line-height: ${13 / 11};
  ${fluidScale({
    minPx: 11, maxPx: 11 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}

  ${theme.bp.tablet} {
    text-align: right;
  }
`;

export const FooterLegal = () => {
  const theme = useTheme();

  const mq500 = theme.bp.min(500);

  return (
    <div
      css={css`
        ${mq500} {
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
        }
      `}
    >
      <small
        css={[
          legalText(theme),
          css`
            display: block;
            margin-bottom: ${theme.spacer(0.5)};
            margin-right: auto;

            ${theme.bp.tablet} {
              margin-right: 0;
            }
          `,
        ]}
      >
        &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        VentureWeb Design Ltd. All Rights Reserved.
      </small>
    </div>
  );
};
