import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { ContentList } from '../components/ContentList';
import { useCaseStudyTaggedQuery } from '../hooks/useCaseStudyTaggedQuery';

const getTaggedNodes = (taggedBy, edges) => (
  edges.reduce((acc, next) => {
    const {
      node: {
        data = {},
        tags = [],
        url,
      } = {},
    } = next || {};

    if (!tags.length || !tags.includes(taggedBy)) {
      return acc;
    }

    acc.push({
      data,
      tags,
      url,
    });

    return acc;
  }, [])
);

const getNodesSpliced = (limit, nodes) => {
  const getSpliceLength = len => (len % 2 === 0 ? len : len - 1);

  if (nodes.length > limit) {
    return nodes.splice(0, limit);
  }

  if (nodes.length > 2) {
    const sLen = getSpliceLength(nodes.length);
    return nodes.splice(0, sLen);
  }

  return nodes;
};

export const CaseStudyTaggedListSlice = ({ slice }) => {
  const {
    block_title: section,
    title,
    card_width: cardWidth,
    card_layout: cardLayout,
    button_label: label,
    button_link: { url },
    background_colour: backgroundColor,
    dotted_background: decoration,
    space_below: spaceBelow,
    tagged_by: taggedBy,
  } = slice.primary;
  const taggedCaseStudies = useCaseStudyTaggedQuery();
  const {
    allPrismicCaseStudy: {
      edges = [],
    } = {},
  } = taggedCaseStudies || {};
  const taggedNodes = getTaggedNodes(taggedBy, edges);
  const nodesSpliced = getNodesSpliced(6, taggedNodes);

  const items = nodesSpliced.map(({
    data: {
      display_title: { text: surtitle },
      intro_title: { text: cardTitle },
      main_hero_image: {
        thumbnails: { card50, card100 },
        alt,
      },
    },
    url: cardUrl,
  }) => {
    const isFullWidth = cardWidth === '100%';

    return {
      image: {
        ...(isFullWidth ? card100 : card50),
        alt,
      },
      imageMobile: isFullWidth ? card50 : null,
      title: cardTitle,
      surtitle,
      url: cardUrl,
      isFullWidth,
      decoration: mapDecorationValues(decoration),
    };
  });
  // @TODO need to move decoration from component to cards, or vice versa
  return (
    <ContentList
      header={{ section, title }}
      layout={cardLayout === 'Straight' ? 'twoRow' : 'twoStagger'}
      button={url ? { url, label } : false}
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{ spaceBelow, items }}
      decoration="none" // @TODO unravel this, think decoration should be on cards
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyCaseStudyTaggedList on PrismicLandingPageDataBodyCaseStudyTaggedList {
    primary {
      block_title
      title
      card_width
      card_layout
      background_colour
      button_label
      button_link {
        url
      }
      dotted_background
      space_below
      tagged_by
    }
  }
  fragment NewsPostDataBodyCaseStudyTaggedList on PrismicNewsPostDataBodyCaseStudyTaggedList {
    primary {
      block_title
      title
      card_width
      card_layout
      background_colour
      button_label
      button_link {
        url
      }
      dotted_background
      space_below
      tagged_by
    }
  }
`;

CaseStudyTaggedListSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      card_width: PropTypes.oneOf(['50%', '100%']),
      card_layout: PropTypes.oneOf(['Straight', 'Staggered']),
      button_label: PropTypes.string,
      button_link: PropTypes.shape({
        url: PropTypes.string,
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
      tagged_by: PropTypes.string,
    }),
  }).isRequired,
};
